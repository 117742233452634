import ModalComponent from "common/components/Modal";
import Typography from "@mui/material/Typography";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TextInput from "common/components/TextInput/TextInput";
import CommonButton from "common/components/Button";
import React, {useEffect, useState} from "react";
import IconButton from '@mui/material/IconButton';
import DropDown from "common/components/DropDown/DropDown";
import MDTable from "common/components/MDTable";
import {useDispatch, useSelector} from "react-redux";
import {getSuppliers} from "services/suppllier/supplier";
import {getItems} from "services/item/item";
import {
    deletePurchaseOrderItem, getAllPurchaseOrders,
    getPurchaseOrderItemsById,
    savePurchaseOrderItem,
    updatePurchaseOrderItem, updatePurchaseOrderStatus
} from "services/purchaseOrderMain";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";
import {Delete} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import {itm} from "config/images";
import {generateDate, size} from "common/utils/CommonFunctions";
import dayjs from "dayjs";

function PurchaseOrderAddModal({open, onClose, isView = false, purchaseDetails}) {

    const dispatch = useDispatch();
    const [viewMode, setViewMode] = useState(isView);
    const [selectedSupplier, setSelectedSupplie] = useState(isView ? purchaseDetails.supplierId : '');
    const [selectedItem, setSelectedItem] = useState('');
    const [qty, setQty] = useState('');
    const [unit, setUnit] = useState('');
    const [purchaseOrderItems, setPurchaseOrderItems] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [items, setItems] = useState([]);
    const [isApprovalDisable, setApprovalDisable] = useState(!isView ? true : purchaseDetails.status !== 'pending');
    const [qtyError, setQtyError] = useState('');
    const [selectedDay, setSelectedDay] = useState('30')
    const [loader, setLoader] = useState(false);
    const [poId, setPoId] = useState(isView ? purchaseDetails.poId : '');
    const [isUpdate, setIsUpdate] = useState(false);
    const [poDetailsId, setPoDetailsId] = useState('');
    const [selectedSize, setSelectedSize] = useState('');
    const supplierData = useSelector(state => state.SupplierSlice.supplier);
    const itemData = useSelector(state => state.ItemSlice.item);
    const poSaveData = useSelector(state => state.SavePurchaseOrderSlice.purchaseOrder);
    const poItemsData = useSelector(state => state.PurchaseOrderItemsSlice.purchaseOrder);
    const poUpdateData = useSelector(state => state.UpdatePurchaseOrderSlice.purchaseOrder);
    const poDeleteData = useSelector(state => state.DeletePurchaseOrderSlice.purchaseOrder);
    const poStatusUpdateData = useSelector(state => state.UpdatePurchaseOrderStatusSlice.purchaseOrder);
    const [itemDisable, setItemDisable] = useState(!isView ? false : purchaseDetails.status !== 'pending')

    const tableColumns = [
        {field: "item", headerName: "Item", accessor: "item", textAlign: 'left'},
        {field: "unit", headerName: "Unit", accessor: "unit", textAlign: 'left'},
        {field: "qty", headerName: "Qty", accessor: "qty", textAlign: 'left'},
        {field: "action", headerName: "Action", accessor: "action", textAlign: 'left'},
    ];
    const searchKey = ['item', 'unit'];

    const isComplete = selectedItem && selectedSupplier && qty && selectedSize && !qtyError;

    const clearFields = () => {
        setSelectedItem('');
        setQty('');
        setSelectedDay('30');
        setSelectedSize('')
    }

    const addPurchaseOrderItem = () => {

        if (isUpdate) {
            const poData = {
                mainPoId: poId,
                qty: qty,
                size: selectedSize,
                itemId: selectedItem.itemId
            }
            setLoader(true);
            dispatch(updatePurchaseOrderItem({id: poDetailsId, updateData: poData}))
        } else {
            if (viewMode) {
                const fltr = purchaseOrderItems.filter(val => val.itemId === selectedItem.itemId)
                if (fltr.length > 0) {
                    sendWarningNotification({
                        title: 'Error',
                        message: 'Item Already Added'
                    })
                } else {
                    const poData = {
                        mainPoId: poId,
                        supplierId: selectedSupplier,
                        poDetails:
                            {
                                qty: qty,
                                size: selectedSize,
                                itemId: selectedItem.itemId
                            }
                    }
                    setLoader(true);
                    dispatch(savePurchaseOrderItem(poData));
                }
            } else {
                //First Add
                const poData = {
                    supplierId: selectedSupplier,
                    poDetails:
                        {
                            qty: qty,
                            size: selectedSize,
                            itemId: selectedItem.itemId
                        }
                }
                setLoader(true);
                dispatch(savePurchaseOrderItem(poData));
            }
        }
    }

    useEffect(() => {
        dispatch(getSuppliers());
        dispatch(getItems());
        setPurchaseOrderItems([]);
        if (isView) {
            dispatch(getPurchaseOrderItemsById(purchaseDetails.poId))
        } else {
            setPurchaseOrderItems([]);
        }
    }, []);

    useEffect(() => {
        if (supplierData.isSuccess && !supplierData.isLoading) {
            const data = supplierData?.data;
            if (Array.isArray(data)) {
                const array = data.map((supplier) => (
                    {
                        label: supplier.name,
                        value: supplier.supplierId
                    }
                ));
                setSuppliers(array);
            }
        }
    }, [supplierData.data]);

    useEffect(() => {
        if (itemData.isSuccess && !itemData.isLoading) {
            const data = itemData?.data;
            if (Array.isArray(data)) {
                const array = data.map((item) => (
                    {
                        label: item.itemName,
                        value: item
                    }
                ));
                setItems(array);
            }
        }
    }, [itemData.data]);

    useEffect(() => {
        if (loader) {
            setLoader(false);
            if (poSaveData.isSuccess && !poSaveData.isLoading) {
                clearFields();
                setViewMode(true);
                setApprovalDisable(false);
                setPoId(poSaveData.data.mainPoId)
                dispatch(getAllPurchaseOrders());
                dispatch(getPurchaseOrderItemsById(poSaveData.data.mainPoId));
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Order Saved Success',
                })
            } else if (!poSaveData.isSuccess && !poSaveData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Order Saved Error'
                })
            }
        }
    }, [poSaveData.data]);

    useEffect(() => {
        if (poItemsData.isSuccess && !poItemsData.isLoading && viewMode) {
            const data = poItemsData?.data.poDetails;
            if (Array.isArray(data)) {
                const array = data.map((item) => (
                    {
                        itemId: item.item.itemId,
                        item: (
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Avatar
                                    sx={{
                                        width: 30,
                                        height: 30,
                                        margin: '0px',
                                        padding: '0px',
                                        backgroundColor: '#EAF5FC',
                                        marginRight: '5px',
                                        marginTop: '8px',
                                    }}
                                    src={itm}
                                />

                                <div style={{marginTop: '10px'}}>{item.item.itemName}</div>
                            </div>
                        ),
                        unit: item.item.unit,
                        qty: item.qty,
                        action: (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <CommonButton
                                    sx={{width: '100px'}}
                                    name={'Update'}
                                    circular={true}
                                    variant={'Fill'}
                                    onClick={() => {
                                        setIsUpdate(true);
                                        setItemDisable(true);
                                        setQty(item.qty);
                                        setSelectedItem(item.item)
                                        setUnit(item.item.unit);
                                        setPoDetailsId(item.poDetailsId);
                                        setSelectedSize(item.size)
                                    }}
                                    disabled={poItemsData?.data.mainPoStatus !== 'pending'}
                                />

                                <IconButton
                                    onClick={() => {
                                        setLoader(true);
                                        dispatch(deletePurchaseOrderItem(item.poDetailsId))
                                    }}
                                    disabled={poItemsData?.data.mainPoStatus !== 'pending'}
                                >
                                    <Delete sx={{color: "#1C5D7C"}}/>
                                </IconButton>
                            </div>
                        ),
                    }
                ));
                setPurchaseOrderItems(array)
            }
        }
    }, [poItemsData.data]);

    useEffect(() => {
        if (loader) {
            setLoader(false);
            if (poUpdateData.isSuccess && !poUpdateData.isLoading) {
                clearFields();
                setIsUpdate(false);
                setItemDisable(false);
                dispatch(getPurchaseOrderItemsById(poId));
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Order Item Update Success',
                })
            } else if (!poUpdateData.isSuccess && !poUpdateData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Order Item Update Error'
                })
            }
        }
    }, [poUpdateData.data]);

    useEffect(() => {
        if (loader) {
            setLoader(false);
            if (poDeleteData.isSuccess && !poDeleteData.isLoading) {
                dispatch(getPurchaseOrderItemsById(poId));
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Order Item Delete Success',
                })
            } else if (!poDeleteData.isSuccess && !poDeleteData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Order Item Delete Error'
                })
            }
        }
    }, [poDeleteData.data]);

    useEffect(() => {
        if (loader) {
            setLoader(false);
            if (poStatusUpdateData.isSuccess && !poStatusUpdateData.isLoading) {
                dispatch(getAllPurchaseOrders());
                onClose();
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Order Send For Approval Success',
                })
            } else if (!poStatusUpdateData.isSuccess && !poStatusUpdateData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Order Send For Approval Error'
                })
            }
        }
    }, [poStatusUpdateData.data]);

    const daysArray = [
        {value: "30", label: "30"},
        {value: "45", label: "45"},
        {value: "other", label: "Other"}
    ]

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="md">
            <MDBox sx={{margin: '10px'}}>
                <Box>
                    <div>
                        <Typography sx={{
                            color: '#1C5E7C',
                            fontWeight: 450,
                            fontSize: '16px',
                            marginBottom: '25px',
                            position: 'absolute',
                            top: '15px',
                            left: '15px'
                        }}> {viewMode ? 'Purchase Order' : 'Add Purchase Order'} </Typography>
                    </div>
                    <div style={{
                        position: 'absolute',
                        top: '8px',
                        left: '150px'
                    }}
                    >
                    </div>
                </Box>

                <div style={{overflow: 'hidden'}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Box sx={{width: '1000px', margin: '0 auto'}}>
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Box sx={{marginY: '10px'}}>
                            <DropDown
                                label={'Supplier'}
                                options={suppliers}
                                value={selectedSupplier}
                                disabled={viewMode}
                                onChange={(value) => {
                                    setSelectedSupplie(value);
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Box sx={{marginY: '10px'}}>
                            <DropDown
                                label={'Item'}
                                options={items}
                                value={selectedItem}
                                onChange={(value) => {
                                    setSelectedItem(value);
                                    setUnit(value.unit);
                                }}
                                disabled={itemDisable}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Box sx={{marginY: '10px'}}>
                            <DropDown
                                label={'Size'}
                                options={size}
                                value={selectedSize}
                                onChange={(value) => {
                                    setSelectedSize(value);
                                }}

                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box sx={{marginY: '10px'}}>
                            <TextInput
                                placeholder={'Unit'}
                                value={unit}
                                onChange={(e) => {
                                    setUnit(e.target.value)
                                }}
                                disabled={true}
                                variant="outlined"
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box sx={{marginY: '10px'}}>
                            <TextInput
                                placeholder={'Qty'}
                                value={qty}
                                onChange={(e) => {
                                    setQty(e.target.value)
                                    if (!/^\d+$/.test(e.target.value)) {
                                        setQtyError("Invalid Qty");
                                    } else {
                                        setQtyError("");
                                    }
                                }}
                                error={qtyError !== ""}
                                variant="outlined"
                                disabled={!isView ? false : purchaseDetails.status !== 'pending'}
                            />
                            {qtyError && <div style={{color: 'red', fontSize: '12px'}}>{qtyError}</div>}
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box sx={{marginY: '10px'}}>
                            <DropDown
                                label={'Days'}
                                options={daysArray}
                                value={selectedDay}
                                onChange={(value) => {
                                    if (value !== 'other') {
                                        const upadatedqty = (parseFloat(qty) / 30) * parseFloat(value);
                                        setQty(upadatedqty);
                                    }
                                    setSelectedDay(value);
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>


                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    {isUpdate &&
                        <div style={{margin: '5px'}}>
                            <CommonButton
                                name={'Cancel'}
                                variant={'outline'}
                                onClick={() => {
                                    setIsUpdate(false);
                                    setItemDisable(false);
                                    clearFields();
                                }}
                            />
                        </div>
                    }
                    <div style={{margin: '5px'}}>
                        <CommonButton
                            name={isUpdate ? 'Update' : 'Add'}
                            variant={'Fill'}
                            onClick={addPurchaseOrderItem}
                            disabled={!isComplete}
                        />
                    </div>
                </Box>

                <MDBox sx={{marginY: 2,}}>
                    <MDTable tableData={purchaseOrderItems} tableColumns={tableColumns} searchKey={searchKey}
                             isSearch={false}/>
                </MDBox>

                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <div style={{margin: '5px'}}>
                        <CommonButton
                            name={'Cancel'}
                            variant={'outline'}
                            onClick={() => {
                                onClose();
                            }}
                        />
                    </div>
                    <div style={{margin: '5px'}}>
                        <CommonButton
                            name={'Send For Approval'}
                            variant={'Fill'}
                            onClick={() => {
                                setLoader(true);
                                dispatch(updatePurchaseOrderStatus({
                                    id: poId,
                                    updateData: {mainPoStatus: "approval", poDate: generateDate(dayjs())}
                                }))

                            }}
                            disabled={isApprovalDisable}
                        />
                    </div>
                </Box>
            </MDBox>
        </ModalComponent>
    );
}

PurchaseOrderAddModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isView: PropTypes.bool,
    purchaseDetails: PropTypes.object,
};

export default PurchaseOrderAddModal;
